.myInput{
    border: 0;
}
.myInput:focus{
    outline: none;
}
.suggestiondiv{
    position: absolute;
    z-index: 99;
    width: 96%;
    background-color: white;
    padding: 8px;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.suggestion{
    cursor: pointer;
}
.suggestion:hover{
    background-color: rgba(0, 0, 0, 0.1);
}